import ApiService from '@/core/services/ApiService';
import JwtService from '@/core/services/JwtService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { RolesEnums } from '@/store/enums/RolesEnums';
import { AxiosRequestConfig } from 'axios';
import { domainUrl, domainUrlAdmin } from '@/core/config/DomainConfig';
import { removeBitrix24Chat, signOut } from '@/assets/ts/_utils/DomHelpers';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  access_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module({ name: 'AuthModule' })
export default class AuthModule extends VuexModule implements UserAuthInfo, StoreModel {
  errors = {};
  redirect = {};
  userRoles: string[] = [];
  user = {} as User;
  legacyBalance = {};
  loadBalance = true;
  version = '';
  isAuthenticated = !!JwtService.getToken();
  isBuild = false;

  /**
     * Get isBuild property
     * @returns boolean
     */
  get [Getters.GET_IS_BUILD](): boolean {
    return this.isBuild;
  }

  /**
   * Get current user object
   * @returns User
   */
  get [Getters.CURRENT_USER](): User {
    return this.user;
  }

  get [Getters.GET_USER_ROLES]() {
    return this.userRoles;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get [Getters.IS_USER_AUTHENTICATED](): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get [ErrorGetters.GET_ERRORS_AUTH]() {
    return this.errors;
  }

  /**
   * Get redirect
   * @returns array
   */
  get [Getters.GET_REDIRECT]() {
    return this.redirect;
  }

  /**
   * Get redirect
   * @returns array
   */
  get [Getters.GET_VERSION]() {
    return this.version;
  }


  /**
   * Get legacy balance object
   * @returns Legacy balance
   */
  get [Getters.LEGACY_BALANCE_DATA]() {
    return this.legacyBalance;
  }

  /**
   * Get legacy balance status
   * @returns boolean
   */
  get [Getters.GET_LEGACY_BALANCE_STATUS]() {
    return this.loadBalance;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](userRoles) {
    this.userRoles = userRoles;
  }

  @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.redirect = {};
    this.user = {} as User;
    this.isAuthenticated = !!JwtService.getToken();
    this.legacyBalance = {};
    this.loadBalance = true;
  };

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_REDIRECT](redirect) {
    this.redirect = redirect;
  }

  @Mutation
  [Mutations.SET_VERSION](version) {
    this.version = version;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.user;
    const roles = user?.user?.roles ?? [];
    this.userRoles = [RolesEnums.ALL, ...roles];
    this.errors = {};
    JwtService.saveToken(user.access_token);
  }


  @Mutation
  [Mutations.SET_USER](user) {
    const roles = user?.roles ?? [];
    this.userRoles = [RolesEnums.ALL, ...roles];
    this.user = user;
  }

    @Mutation
  [Mutations.SET_IS_BUILD](payload) {
    this.isBuild = payload;
  }

  @Mutation
    [Mutations.SET_PASSWORD](password) {
      this.user.password = password;
    }

  @Mutation
  [Mutations.SET_LEGACY_BALANCE](payload) {
    this.legacyBalance = payload;
  }

  @Mutation
  [Mutations.SET_LEGACY_BALANCE_STATUS](payload) {
    this.loadBalance = payload;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post(`${domainUrl}login`, credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }

  @Action
  [Actions.CHANGE_USER_ACCOUNT](credentials) {
    return ApiService.post(`${domainUrlAdmin}auth/change-user-account`, credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }


  @Action
  [Actions.LOGOUT]() {
    return ApiService.post(`${domainUrl}logout`, {})
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        removeBitrix24Chat();
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
        localStorage.removeItem('access_token');
        removeBitrix24Chat();
      });

  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post(`${domainUrl}register`, credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
        if (response.data.redirect) {
          this.context.commit(Mutations.SET_REDIRECT, response.data.redirect);
        }
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post(`${domainUrl}forgot-password`, payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }

  @Action
  [Actions.EMAIL_VERIFICATION_NOTIFICATION](payload) {
    return ApiService.post(`${domainUrl}email/verification-notification`, payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }

  @Action
  [Actions.PASSWORD_RESET](payload) {
    return ApiService.post(`${domainUrl}password-reset-store`, payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }

  @Action
  [Actions.VERSION](payload) {
    return ApiService.get('/version.json')
      .then(({ data }) => {
        if (this.version !== data?.version) {
          if (this.version !== '') {
            signOut();
          }
          this.context.commit(Mutations.SET_VERSION, data?.version);
        }
      });
  }

  @Action
  async [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.post(`${domainUrl}me`, payload)
        .then(async ({ data }) => {
          if (data?.is_build) {
            this.context.commit(Mutations.SET_IS_BUILD, data?.is_build);
          }
          this.context.commit(Mutations.SET_USER, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_LOCALE](payload) {
    ApiService.post(`${domainUrl}changeLocale`, payload)
      .then(({ data }) => {
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }

  @Action
  [Actions.GET_LEGACY_BALANCE](params): Promise<any> {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: params
    };
    return ApiService.query(`${domainUrl}users/legacy/balance`, config)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LEGACY_BALANCE, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data?.errors);
      });
  }
}
