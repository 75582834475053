import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { trim } from 'lodash';
import { MarketingActionTypeEnumModel } from '@/assets/ts/_utils/models/ConstantEnumModel';
import { getConstantObject } from '@/assets/ts/_utils/MainHelper';
import { PriceplansUtil } from '@/assets/ts/_utils/PriceplansUtil';
import moment from 'moment/moment';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import { useStore } from 'vuex';
import { ErrorTypeEnums } from '@/store/enums/ErrorTypeEnums';

export class PaymentDispatcherService extends StoreGettersService{

  constructor (store = useStore()) {super(store);}

  public async updateUserPayment(id, paysystem_id, description) {
    const params = {
      id: id,
      description: description
    };
    if (paysystem_id) {
      params['paysystem_id'] = paysystem_id;
    }

    await this.getStore().dispatch(Actions.UPDATE_USER_PAYMENT, params);
  }

  public async deleteUserPayment(paymentId) {
    await this.getStore().dispatch(Actions.DELETE_USER_PAYMENT, paymentId);
  }

  public async getUserPayment(
    search,
    columnFilterValue,
    rowsPerPage,
    priceplan,
    currentPage,
    sortBy,
    orderBy,
    balanceType,
    paymentType,
  ) {
    const params = {
      successOnly: true
    };

    if (search) {
      const value = search;
      params['search'] = trim(value);
    }
    if (columnFilterValue) {
      params['searchColumn'] = columnFilterValue;
    }
    if (rowsPerPage) {
      params['limit'] = rowsPerPage;
    }
    if (priceplan) {
      params['priceplan_id'] = priceplan;
    }
    if (currentPage) {
      params['page'] = currentPage;
    }
    if (sortBy) {
      params['sortBy'] = sortBy;
    }
    if (orderBy) {
      params['orderBy'] = orderBy;
    }
    if (balanceType) {
      params['pay_systems'] = balanceType;
    }
    if (paymentType) {
      params['payment_type'] = paymentType;
    }

    this.getStore().commit(Mutations.SET_USER_PAYMENTS_QUERY_PARAMS, params);
    return await this.getStoreGettersUserPayment(params);
  }

  public async getStoreGettersUserPayment(params = {}) {
    return await this.getAllRecordsByGetter(Getters.ALL_USER_PAYMENTS, Actions.GET_USER_PAYMENT, params, true);
  }

  public async getPayments(options) {
    await this.getStore().dispatch(Actions.GET_PAYMENTS, options);
  }

  public async transferBetweenAccounts(amount) {
    return await this.getStore().dispatch(Actions.TRANSFER_BETWEEN_ACCOUNTS, { amount: amount, mode: 1, });
  }

  public async getLegacyBalance() {
    return await this.getStore().dispatch(Actions.GET_LEGACY_BALANCE, {});
  }

  public async getPaymentConfiguration(paymentId, amount) {
    return await this.getStoreGettersPaymentConfiguration({
      paymentId: paymentId,
      amount: amount
    });
  }

  public async getStoreGettersPaymentConfiguration(params = {}) {
    return await this.getAllRecordsByGetter(Getters.GET_PAYMENT_CONFIGURATIONS, Actions.GET_PAYMENT_CONFIGURATION, params, true);
  }

  public async postPaymentCheckout(paysystem_id, amount) {
    const params = {
      amount: amount,
      paysystem_id: paysystem_id
    };
    await this.getStore().dispatch(Actions.POST_PAYMENT_CHECKOUT, params);
  }

  public getTopUpBalanceRequests() {
    const MarketingActionTypeEnum: MarketingActionTypeEnumModel = getConstantObject(this.getStore(), 'MarketingActionTypeEnum');
    return [
      this.getTotalPayments(),
      this.getStore().dispatch(Actions.GET_PAYSYSTEMS, {}),
      this.getStore().dispatch(Actions.GET_CURRENT_MARKETING_ACTION, { type: MarketingActionTypeEnum.PaymentBonus }),
    ];
  }

  public getTotalPayments = async () => {
    const allPayments = await this.getStoreGettersTotalPayments();
    if (typeof allPayments?.data?.total !== 'undefined') {
      PriceplansUtil.totalPayments.value = allPayments?.data?.total;
    }
    if (allPayments?.data?.latest?.created_at) {
      PriceplansUtil.lastPayments.value = moment(allPayments?.data?.latest?.created_at).format('DD.MM.YYYY');
    }
  };

  public async getStoreGettersTotalPayments(params = {}) {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_PAYMENTS, Actions.GET_TOTAL_PAYMENTS, params, true);
  }

  public async getAdminPaysystems (params, reloadObject = false) {
    return await this.getAllRecordsByGetter(Getters.GET_ADMIN_PAY_SYSTEMS, Actions.GET_ADMIN_PAYSYSTEMS, params, reloadObject);
  }

  public getValidatedGetterPaymentLink () {
    return this.getValidatedGetter(Getters.GET_PAYMENT_LINK);
  }

  public getValidatedLegacyBalanceStatus () {
    return this.getValidatedGetter(Getters.GET_LEGACY_BALANCE_STATUS);
  }

  public getValidatedListPayments () {
    return this.getValidatedGetter(Getters.GET_LIST_PAYMENTS);
  }
  public getValidatedBillingsRows () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_ROWS);
  }
  public getValidatedBillingsSearch () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_SEARCH);
  }
  public getValidatedBillingsCurrentPage () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_CURRENT_PAGE);
  }
  public getValidatedBillingsSortBy () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_SORT_BY);
  }
  public getValidatedBillingsSortType () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_SORT_TYPE);
  }

  public getValidatedSelectedPaysystemId () {
    return this.getValidatedGetter(Getters.GET_SELECTED_PAYSYSTEM_ID);
  }
  public getValidatedBonus () {
    return this.getValidatedGetter(Getters.GET_BONUS);
  }
  public getValidatedBillingsPage () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_PAGE);
  }
  public getValidatedBillingsLastPage () {
    return this.getValidatedGetter(Getters.GET_BILLINGS_LAST_PAGE);
  }

  public getValidatedUserPaymentsPage () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_PAGE);
  }
  public getValidatedUserPaymentsRows () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_ROWS);
  }
  public getValidatedUserPaymentsCurrentPage () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_CURRENT_PAGE);
  }
  public getValidatedUserPaymentsLastPage () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_LAST_PAGE);
  }

  public getValidatedAllUserPayments () {
    return this.getValidatedGetter(Getters.ALL_USER_PAYMENTS);
  }

  public getValidatedUserPaymentsQueryParams () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_QUERY_PARAMS);
  }
  public getValidatedUserPaymentsExport () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_EXPORT);
  }
  public getValidatedUserPaymentsSearch () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_SEARCH);
  }
  public getValidatedUserPaymentsPriceplan () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_PRICEPLAN);
  }
  public getValidatedUserPaymentsSortBy () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_SORT_BY);
  }
  public getValidatedUserPaymentsOrderBy () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_ORDER_BY);
  }
  public getValidatedUserPaymentsBalanceType () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_BALANCE_TYPE);
  }
  public getValidatedUserPaymentsPaymentType () {
    return this.getValidatedGetter(Getters.GET_USER_PAYMENTS_PAYMENT_TYPE);
  }
  public getValidatedAdminUserPaymentsColumnSearchFilter () {
    return this.getValidatedGetter(Getters.GET_ADMIN_USER_PAYMENTS_COLUMN_SEARCH_FILTER);
  }
  public getValidatedTotalUserPayments () {
    return this.getValidatedGetter(Getters.GET_TOTAL_USER_PAYMENTS);
  }

  public getValidatedOptions () {
    return this.getValidatedGetter(Getters.GET_OPTIONS);
  }

  public getValidatedPayments () {
    return this.getValidatedGetter(Getters.GET_PAYMENTS);
  }

}
