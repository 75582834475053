import { useStore } from 'vuex';
import { ConstantDispatcherService } from '@/store/services/ConstantDispatcherService';

function getCheckedElement(arr: any[] = [], result: number[] = []) {
  arr.forEach(({ checked, value }) => {
    if (checked === true) {
      const numberValue = Number.parseInt(value);
      if (!result.find(el => el === Number.parseInt(value))) {
        result.push(numberValue);
      }
    }
  });
}

function parseStrToBoolean(paramValue: string):boolean{
  switch (paramValue.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(paramValue);
  }
}
function convertEnumValues(
  value: number | string,
  fromEnum: object,
  toEnum: object
): string | number | undefined {
  const key = String(value);

  if (key in fromEnum) {
    const entries = Object.entries(toEnum);
    const findItem = entries.find(el => el[0] === fromEnum[key]);
    if (findItem) {
      return findItem[1];
    }

  }
  return undefined; // Если значение не найдено
}


function formatPrice(price, fractionDigits: number = 2) {
  return Number.parseFloat(price).toFixed(fractionDigits);
}

async function loadData(store = useStore()) {
  const ConstantDispatcher = new ConstantDispatcherService(store);
  const allRequests: Promise<any>[] = await ConstantDispatcher.getDataLoading();
  Promise.all(allRequests)
    .then((responses) => {
    })
    .catch((error) => {
      console.log(error);
    });
}

function matchDay(day) {
  const regex = /day_(\d+)/;
  const match = regex.exec(day);
  return match ? parseInt(match[1]) : null;
}

function getPrice(amount) {
  const parts = formatPrice(amount).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
}

function getConstantObject(store = useStore(), constantName: string) {
  const ConstantDispatcher = new ConstantDispatcherService(store);
  const allConstants = ConstantDispatcher.getValidatedAllConstants();
  if (allConstants.length) {
    return allConstants?.find((obj: object) => constantName in obj)[constantName] as any;
  }
  return allConstants;
}

export {
  convertEnumValues,
  parseStrToBoolean,
  matchDay,
  loadData,
  formatPrice,
  getCheckedElement,
  getConstantObject,
  getPrice,
};
