import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { AxiosRequestConfig } from 'axios';
import ApiService from '@/core/services/ApiService';
import { StoreModel } from '@/assets/ts/_utils/models/StoreModel';
import { domainUrlAdmin } from '@/core/config/DomainConfig';
import { ErrorGetters } from '@/store/enums/ErrorGettersEnums';
import { Getters } from '@/store/enums/GettersEnums';

@Module({ name: 'GoogleAdsReportModule' })
export default class GoogleAdsReportModule extends VuexModule implements StoreModel {
  errors = {};
  googleAds = [];
  export = [];
  queryParams = {};
  totalGoogleAd = 0;
  currentPage = 1;
  lastPage = 1;
  page = 1;
  rowsPerPage = 10;
  search = '';
  from = '';
  to = '';
  limit = 10;
  orderBy = 'id';
  sortBy = 'desc';
  type = 1;
  dontExistsRefCodes = [];

  /**
   * Get current page
   * @returns number
   */
  get [Getters.GET_GOOGLE_ADS_CURRENT_PAGE]() {
    return this.currentPage;
  }

  get [Getters.GET_GOOGLE_ADS_TYPE]() {
    return this.type;
  }

  get [Getters.GET_GOOGLE_ADS_FROM_DATE]() {
    return this.from;
  }

  get [Getters.GET_GOOGLE_ADS_TO_DATE]() {
    return this.to;
  }

  /**
   * Get last page
   * @returns number
   */
  get [Getters.GET_GOOGLE_ADS_LAST_PAGE]() {
    return this.lastPage;
  }

  /**
   * Get page
   * @returns number
   */
  get [Getters.GET_GOOGLE_ADS_PAGE]() {
    return this.page;
  }

  /**
   * Get rows
   * @returns number
   */
  get [Getters.GET_GOOGLE_ADS_ROWS]() {
    return this.rowsPerPage;
  }

  /**
   * Get search
   * @returns string
   */
  get [Getters.GET_GOOGLE_ADS_SEARCH]() {
    return this.search;
  }

  /**
   * Get limit
   * @returns string
   */
  get [Getters.GET_GOOGLE_ADS_LIMIT]() {
    return this.limit;
  }

  /**
   * Get  errors
   * @returns array
   */
  get [ErrorGetters.GET_ERRORS_GOOGLE_ADS]() {
    return this.errors;
  }

  /**
   * Get current Transaction object
   * @returns array
   */
  get [Getters.ALL_GOOGLE_ADS]() {
    return this.googleAds;
  }

  /**
   * Get current Transaction object
   * @returns array
   */
  get [Getters.ALL_GOOGLE_ADS_EXPORT]() {
    return this.export;
  }

  /**
   * Get current Total Transactions
   * @returns number
   */
  get [Getters.GET_TOTAL_GOOGLE_ADS]() {
    return this.totalGoogleAd;
  }

  /**
   * Get  errors
   * @returns array
   */
  get [Getters.GET_GOOGLE_ADS_ORDER_BY]() {
    return this.orderBy;
  }

  /**
   * Get  errors
   * @returns array
   */
  get [Getters.GET_GOOGLE_ADS_QUERY_PARAMS]() {
    return this.queryParams;
  }

  /**
   * Get  errors
   * @returns array
   */
  get [Getters.GET_GOOGLE_ADS_SORT_BY]() {
    return this.sortBy;
  }

  get [Getters.GET_GOOGLE_ADS_DONT_EXISTS_REF_CODES]() {
    return this.dontExistsRefCodes;
  }

  @Mutation
  [Mutations.CLEAR_STORE]() {
    this.errors = {};
    this.googleAds = [];
    this.export = [];
    this.queryParams = {};
    this.totalGoogleAd = 0;
    this.type = 0;
    this.currentPage = 1;
    this.lastPage = 1;
    this.page = 1;
    this.rowsPerPage = 10;
    this.search = '';
    this.from = '';
    this.to = '';
    this.limit = 10;
    this.orderBy = 'id';
    this.sortBy = 'desc';
    this.dontExistsRefCodes = [];
  };

  @Mutation
  [Mutations.SET_GOOGLE_ADS_ROWS](payload) {
    this.rowsPerPage = payload;
  }

  @Mutation
  [Mutations.SET_ERROR_GOOGLE_ADS](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_ALL_GOOGLE_ADS](payload) {
    this.googleAds = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_EXPORT](payload) {
    this.export = payload;
  }

  @Mutation
  [Mutations.SET_TOTAL_GOOGLE_ADS](payload) {
    this.totalGoogleAd = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_CURRENT_PAGE](payload) {
    this.currentPage = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_LAST_PAGE](payload) {
    this.lastPage = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_SEARCH](payload) {
    this.search = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_ORDER_BY](payload) {
    this.orderBy = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_SORT_BY](payload) {
    this.sortBy = payload;
  }

  @Mutation
  [Mutations.SET_GOOGLE_ADS_QUERY_PARAMS](payload) {
    this.queryParams = payload;
  }

  @Mutation
  [Mutations.SET_DONT_EXISTS_REF_CODES_GOOGLE_ADS](payload) {
    this.dontExistsRefCodes = payload;
  }
  @Mutation
  [Mutations.SET_FROM_GOOGLE_ADS](payload) {
    this.from = payload;
  }

  @Mutation
  [Mutations.SET_TO_GOOGLE_ADS](payload) {
    this.to = payload;
  }

  @Mutation
  [Mutations.SET_TYPE_GOOGLE_ADS](payload) {
    this.type = payload;
  }

  @Action
  [Actions.GET_GOOGLE_ADS](params) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: params
    };

    return ApiService.query(`${domainUrlAdmin}google-ads-report`, config)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TOTAL_GOOGLE_ADS, data.meta.total);
        this.context.commit(Mutations.SET_GOOGLE_ADS_CURRENT_PAGE, data.meta.current_page);
        this.context.commit(Mutations.SET_GOOGLE_ADS_LAST_PAGE, data.meta.last_page);
        this.context.commit(Mutations.SET_ALL_GOOGLE_ADS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, response?.data?.errors);
      });
  }

  @Action
  [Actions.GET_GOOGLE_ADS_EXPORT](params) {
    const config: AxiosRequestConfig = {
      method: 'GET',
      params: params
    };
    return ApiService.query(`${domainUrlAdmin}google-ads-report`, config)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GOOGLE_ADS_EXPORT, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, response?.data?.errors);
      });
  }

  @Action
  [Actions.STORE_GOOGLE_ADS](params) {
    return ApiService.fileUpload(`${domainUrlAdmin}google-ads-report`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, {});
        this.context.commit(Mutations.SET_DONT_EXISTS_REF_CODES_GOOGLE_ADS, data.dontExistsRefCodes);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, response?.data?.errors);
      });
  }

  @Action
  [Actions.UPDATE_GOOGLE_ADS_AMOUNT](data) {
    const { id, ...params } = data;
    return ApiService.put(`${domainUrlAdmin}google-ads-report/${id}`, params)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, response?.data?.errors);
      });
  }

  @Action
  [Actions.DELETE_GOOGLE_ADS](id) {
    return ApiService.delete(`${domainUrlAdmin}google-ads-report/${id}`)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR_GOOGLE_ADS, response?.data?.errors);
      });
  }
}
