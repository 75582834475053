enum Actions {
    // action types
    ADD_BODY_CLASSNAME = 'addBodyClassName',
    REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
    ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
    REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
    ADD_CLASSNAME = 'addClassName',
    VERIFY_AUTH = 'verifyAuth',
    CHANGE_LOCALE = 'changeLocale',
    LOGIN = 'login',
    CHANGE_USER_ACCOUNT = 'changeUserAccount',
    LOGOUT = 'logout',
    REGISTER = 'register',
    REDIRECT = 'redirect',
    UPDATE_USER = 'updateUser',
    FORGOT_PASSWORD = 'forgotPassword',
    EMAIL_VERIFICATION_NOTIFICATION = 'emailVerificationNotification',
    PASSWORD_RESET = 'passwordReset',
    SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
    GET_ROLES = 'getRoles',
    VERSION = 'Version',
    GET_CPI_AD_ASSETS_ACTION = 'getCpiAdAssetsAction',
    GET_ERROR = 'getError',
    GET_COUNTRIES = 'getCountries',
    GET_LANGUAGES = 'getLanguages',
    GET_COUNTRY = 'getCountry',
    GET_APPS = 'getApps',
    GET_COUNT = 'getCount',
    GET_SEARCH_APPS = 'getSearchApps',
    GET_APP = 'getApp',
    ADD_ROLE = 'addRole',
    ADD_APP = 'addApp',
    ADD_CAMPAIGNS = 'addCampaigns',
    START_CAMPAIGN = 'startCampaign',
    PAUSE_CAMPAIGN = 'pauseCampaign',
    CANCEL_CAMPAIGN = 'cancelCampaign',
    RESUME_CAMPAIGN = 'resumeCampaign',
    SEND_FOR_MODERATION = 'sendForModeration',
    FORCED_COMPLETION = 'forcedCompletion',
    FORCED_DRAFT = 'forcedDraft',
    ARCHIVE_CAMPAIGN = 'archiveCampaign',
    ARCHIVE_ALL_CAMPAIGN = 'archiveAllCampaign',
    GET_MY_CAMPAIGN_FILTERS = 'getMyCampaignFilters',
    CLONE_CAMPAIGN = 'cloneCampaign',
    CURRENT_CAMPAIGN = 'currentCampaign',
    UPDATE_CAMPAIGN = 'updateCampaign',
    UPDATE_CAMPAIGN_CURRENT = 'updateCampaignCurrent',
    ADD_KEYWORD = 'addKeyword',
    DELETE_ROLE = 'deleteRole',
    DELETE_APP = 'deleteApp',
    DELETE_BANNED_APPS = 'deleteBannedApps',
    DELETE_KEYWORD = 'deleteKeyword',
    TOGGLE_FAVORITE_APP = 'toggleFavoriteApp',
    TOGGLE_FAVORITE_CAMPAIGN = 'toggleFavoriteCampaign',
    TOGGLE_FAVORITE_KEYWORD = 'toggleFavoriteKeyword',
    CLONE_KEYWORDS = 'cloneKeywords',
    UPDATE_ROLE = 'updateRole',
    UPDATE_APP = 'updateApp',
    GET_ROLE = 'getRole',
    GET_APPMAN_APP = 'getAppmanApp',
    GET_PRICE_PLAN = 'getPricePlan',
    GET_REVIEWS_COMMON = 'getReviewsCommon',
    GET_CAMPAIGNS = 'getCampaigns',
    GET_CAMPAIGNS_PARTNERS = 'getCampaignsPartners',
    SET_CAMPAIGN_PARTNER = 'setCampaignPartner',
    SET_CAMPAIGNS_PARTNER = 'setCampaignPartner',
    CREATE_CAMPAIGN_VENDOR = 'createCampaignVendor',
    ADD_PERMISSION_MODULE = 'addPermissionModule',
    DELETE_PERMISSION_MODULE = 'deletePermissionModule',
    UPDATE_PERMISSION_MODULE = 'updatePermissionModule',
    ADD_PERMISSION_FUNCTION = 'addPermissionFunction',
    DELETE_PERMISSION_FUNCTION = 'deletePermissionFunction',
    UPDATE_PERMISSION_FUNCTION = 'updatePermissionFunction',
    GET_PERMISSION_MODULE = 'getPermissionModule',
    GET_PERMISSION_MODULES = 'getPermissionModules',
    GET_PERMISSION_MODULES_PAGE = 'getPermissionModulesPage',
    GET_KEYWORDS = 'getKeywords',
    GET_KEYWORDS_COUNT = 'getKeywordsCount',
    GET_PERMISSION_FUNCTION = 'getPermissionFunction',
    GET_PERMISSION_FUNCTIONS = 'getPermissionFunctions',
    GET_SUGGEST_KEYWORDS = 'getSuggestKeyword',
    GET_CHART_KEYWORD = 'getChartKeyword',
    GET_CHART_KEYWORDS_MANY = 'getChartKeywordsMany',
    DELETE_KEYWORDS_MANY = 'deleteKeywordsMany',
    GET_TOP_APPS_BY_KEYWORD = 'getTopAppsByKeyword',
    GET_RANK_KEYWORD = 'getRankKeyword',
    UPDATE_ALL_RANKS = 'updateAllRanks',
    GET_TRANSACTIONS = 'getTransactions',
    GET_TRANSACTIONS_EXPORT = 'getTransactionsExport',
    GET_PRICEPLANS = 'getPriceplans',
    GET_CURRENT_PRICEPLAN = 'getCurrentPriceplan',
    GET_TOTAL_PAYMENTS = 'getTotalPayments',
    GET_CONSTANTS = 'getConstants',
    GET_PAYMENTS = 'getPayments',
    GET_USER_DATA = 'getUserData',
    POST_USER_DATA = 'postUserData',
    POST_USER_DATA_IMAGE = 'postUserDataImage',
    DELETE_USER_DATA_IMAGE = 'deleteUserDataImage',
    GET_PLATFORM_NEWS = 'getPlatformNews',
    GET_FAQS = 'getFAQs',
    GET_CAMPAIGNS_TOTAL = 'getCampaignsTotal',
    GET_GEOS = 'getGEOs',
    GET_TOP_REGIONS = 'getTopRegions',
    GET_MY_APPS = 'getMyApps',
    GET_STATISTICS_PRODUCTS = 'getStatisticsProducts',
    GET_STATISTICS_KEYWORDS = 'getStatisticsKeywords',
    GET_STATISTICS_CAMPAIGNS = 'getStatisticsCampaigns',
    GET_STATISTICS_PRODUCTS_EXPORT = 'getStatisticsProductsExport',
    GET_STATISTICS_KEYWORDS_EXPORT = 'getStatisticsKeywordsExport',
    GET_STATISTICS_CAMPAIGNS_EXPORT = 'getStatisticsCampaignsExport',
    GET_CAMPAIGNS_ARCHIVED = 'getCampaignsArchived',
    POST_PAYMENT_CHECKOUT = 'postPaymentCheckout',
    GET_PAYMENT_CONFIGURATION = 'getPaymentConfiguration',
    GET_CAMPAIGNS_WIDGETS = 'getCampaignsWidgets',
    GET_PINNED_NOTIFICATION = 'getPinnedNotification',
    GET_PAYSYSTEMS = 'getPaysystems',
    GET_ADMIN_PAYSYSTEMS = 'getAdminPaysystems',
    CHANGE_IS_ACTIVE_PAYSYSTEM = 'changeIsActivePaysystem',
    GET_MANAGERS = 'getManagers',
    GET_NOTICE_TEMPLATES = 'getNoticeTemplates',
    STORE_NOTICE = 'storeNotice',
    GET_NOTICES = 'getNotices',
    LOAD_NOTICES = 'loadNotices',
    REQUEST_MANAGER = 'requestManager',
    VIEW_NOTICE = 'viewNotice',
    GET_ACCESS_LIST = 'getAccessLists',
    ADD_ACCESS_LIST = 'addAccessList',
    DELETE_ACCESS_LIST = 'deleteAccessList',
    GET_LEGACY_BALANCE = 'getLegacyBalance',

    GET_USER_LIST = 'getUserList',
    GET_USER_LIST_EXPORT = 'getUserListExport',
    UPDATE_USER_LIST = 'updateUserList',
    GET_USER = 'getUser',
    DELETE_USER_LIST = 'deleteUserList',

    GET_USER_DETAILS_PAYMENTS = 'getUserDetailsPayments',
    GET_USERS_REFERRALS = 'getUsersReferrals',
    GET_USERS_REFERRALS_EXPORT = 'getUsersReferralsExport',
    GET_USERS_PAYOUTS = 'getUsersPayouts',
    POST_USERS_PAYOUT = 'getUsersPayout',
    GET_WIDGETS_REFERRALS = 'getWidgetsReferrals',
    CHANGE_USER_BALANCE = 'changeUserBalance',

    GET_ADMIN_REFLINKS = 'getAdminReflinks',
    GET_ADMIN_REFLINKS_EXPORT = 'getAdminReflinksExport',
    ADD_ADMIN_REFLINKS = 'addAdminReflinks',
    UPDATE_ADMIN_REFLINK = 'updateAdminReflink',
    DELETE_ADMIN_REFLINKS = 'deleteAdminReflinks',
    GET_CONTACTS = 'getContacts',
    RESELLER_PROGRAM_STORE = 'resellerProgramStore',
    TRANSFER_BETWEEN_ACCOUNTS = 'transferBetweenAccounts',
    GET_USER_ORDERS = 'getUserOrders',
    GET_USER_ORDER_STATUS_HISTORY = 'getUserOrderStatusHistory',
    GET_USER_ORDERS_EXPORT = 'getUserOrdersExport',
    UPDATE_USER_ORDER = 'updateUserOrder',
    GET_CPI_ACCOUNTS = 'getCpiAccounts',
    CURRENT_USER_ORDER = 'currentUserOrder',
    START_USER_ORDER = 'startUserOrder',
    REJECTED_USER_ORDER = 'rejectedUserOrder',
    PAUSE_USER_ORDER = 'pauseUserOrder',
    CANCEL_USER_ORDER = 'cancelUserOrder',
    RESUME_USER_ORDER = 'resumeUserOrder',
    ARCHIVE_USER_ORDER = 'archiveUserOrder',

    GET_NOTIFICATIONS = 'getNotifications',
    DEACTIVATE_NOTIFICATION = 'deactivateNotification',

    GET_VENDORS = 'getVendors',
    GET_VENDORS_CAMPAIGNS_LIST = 'getVendorsCampaignsList',
    GET_VENDORS_CAMPAIGNS_EXPORT = 'getVendorsCampaignsExport',
    CHANGE_ADMIN_VENDORS_ACTIVITY = 'changeAdminVendorsActivity',
    CHANGE_ADMIN_COUNTRY_VENDORS_ACTIVITY = 'changeAdminCountryVendorsActivity',
    STORE_VENDORS = 'storeVendors',
    UPDATE_VENDORS = 'updateVendors',
    DELETE_VENDORS = 'deleteVendors',

    GET_USER_PAYMENT = 'getUserPayment',
    GET_USER_PAYMENT_EXPORT = 'getUserPaymentExport',
    UPDATE_USER_PAYMENT = 'updateUserPayment',
    DELETE_USER_PAYMENT = 'deleteUserPayment',

    GET_USER_DETAILS_MESSAGES = 'getUserDetailsMessages',

    GET_USER_APPS = 'getUserApps',
    GET_USER_APPS_EXPORT = 'getUserAppsExport',

    GET_ADMIN_EMAIL_TEMPLATES = 'getAdminEmailTemplates',
    UPDATE_ADMIN_EMAIL_TEMPLATES = 'updateAdminEmailTemplates',

    GET_USER_EMAIL_TEMPLATES = 'getUserEmailTemplates',
    GET_USER_EMAIL_TEMPLATES_LIST = 'getUserEmailTemplatesList',
    UPDATE_USER_EMAIL_TEMPLATES = 'updateUserEmailTemplates',

    GET_SALES_REPORT_MY_CLIENTS = 'getSalesReportMyClients',
    GET_SALES_REPORT_MY_CLIENTS_EXPORT = 'getSalesReportMyClientsExport',
    CHANGE_SALES_REPORT_MY_CLIENTS_STATUS = 'changeSalesReportMyClientsStatus',
    CHANGE_SALES_REPORT_MY_CLIENTS_HISTORY_NOTE = 'changeSalesReportMyClientsHistoryNote',

    GET_SALES_REPORT_NEW_CLIENTS = 'getSalesReportNewClients',
    CHANGE_SALES_REPORT_NEW_CLIENTS_MANAGER = 'changeSalesReportNewClientsManager',

    GET_SALES_REPORT_STATISTIC_BY_STATUSES = 'getSalesReportStatisticByStatusesAction',
    GET_SALES_REPORT_STATISTIC_BY_PAYMENTS = 'getSalesReportStatisticByPaymentsAction',
    GET_SALES_REPORT_STATISTIC_LAST_PAYMENTS = 'getSalesReportStatisticLastPaymentsAction',
    GET_SALES_REPORT_STATISTIC_TOTALS = 'getSalesReportStatisticTotals',

    GET_ADMIN_PRODUCTS = 'getAdminProductsAction',
    UPDATE_ADMIN_PRODUCTS = 'updateAdminProducts',
    GET_PRODUCTS = 'getProducts',

    CHANGE_AUTO_REFRESH_CREDITS = 'changeAutoRefreshCredits',
    BUY_API_CREDITS = 'buyApiCredits',
    RESET_API_TOKEN = 'resetApiToken',

    GET_ADMIN_MARKETING_ACTIONS = 'getAdminMarketingActions',
    GET_ADMIN_MARKETING_ACTIONS_EXPORT = 'getAdminMarketingActionsExport',
    ADD_ADMIN_MARKETING_ACTIONS = 'addAdminMarketingActions',
    UPDATE_ADMIN_MARKETING_ACTIONS = 'updateAdminMarketingActions',
    GET_ADMIN_MARKETING_ACTIONS_FILTERS = 'getAdminMarketingActionsFiltersAction',
    DELETE_ADMIN_MARKETING_ACTIONS = 'deleteAdminMarketingActions',
    GET_CURRENT_MARKETING_ACTION = 'getCurrentMarketingAction',

    GET_CLIENTS_REPORT = 'getClientsReport',
    GET_CLIENTS_REPORT_EXPORT = 'getClientsReportExport',
    GET_USER_DETAILS_TRANSACTIONS = 'getUserDetailsTransactions',
    GET_USER_DETAILS_TRANSACTIONS_EXPORT = 'getUserDetailsTransactionsExport',
    DELETE_USER_DETAILS_TRANSACTION = 'deleteUserDetailsTransaction',
    GET_USER_DETAILS_APPS = 'getUserDetailsApps',

    GET_USER_ORDER_PROGRESS = 'getUserOrderProgress',

    GET_USER_DETAILS_REFERRALS = 'getUserDetailsReferrals',
    GET_USER_DETAILS_REFERRALS_EXPORT = 'getUserDetailsReferralsExportAction',
    STORE_USER_DETAILS_REFERRALS = 'storeUserDetailsReferrals',
    DELETE_USER_DETAILS_REFERRALS = 'deleteUserDetailsReferrals',

    GET_GOOGLE_ADS = 'getGoogleAds',
    GET_GOOGLE_ADS_EXPORT = 'getGoogleAdsExport',
    STORE_GOOGLE_ADS = 'storeGoogleAds',
    UPDATE_GOOGLE_ADS_AMOUNT = 'updateGoogleAdsAmount',
    DELETE_GOOGLE_ADS = 'deleteGoogleAds',
}

enum Mutations {
    // mutation types
    CLEAR_STORE = 'clearStore',
    SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
    PURGE_AUTH = 'logOut',
    OPEN_CHAT = 'openChat',
    LOADING_SHOW = 'loadingShow',
    CLOSE_CHAT = 'closeChat',
    SET_AUTH = 'setAuth',
    SET_USER = 'setUser',
    SET_VERSION = 'setVersion',
    SET_PASSWORD = 'setPassword',
    SET_IS_BUILD = 'setIsBuild',
    SET_TEXT_ERROR = 'setTextError',
    SET_GRAPHIC_KEYWORD = 'setChartKeyword',
    SET_LAST_ADDED_KEYWORD_NAME = 'setLastAddedKeywordName',
    SET_SERIALS_CHART = 'setSerialsChart',
    SET_REVIEWS_COMMON = 'setReviewsCommon',
    SET_CPI_AD_ASSETS = 'setCpiAdAssets',
    SET_KEYWORDS_ID = 'setKeywordsId',
    SET_KEYWORD_RANK_UPDATE = 'setKeywordRankUpdate',
    SET_KEYWORD_RANK_UPDATE_EMPTY = 'setKeywordRankUpdateEmpty',
    SET_GRAPHIC_KEYWORDS = 'setChartKeywords',
    SET_ERROR = 'setError',
    SET_REDIRECT = 'setRedirect',
    SET_ERROR_ROLE = 'setErrorRole',
    SET_ERROR_APP = 'setErrorApp',
    SET_ERROR_COUNTRY = 'setErrorCountry',
    SET_USER_ROLES = 'setErrorCountry',
    SET_ERROR_LANGUAGE = 'setErrorLanguage',
    SET_ERROR_PERMISSION = 'setErrorPermission',
    SET_ERROR_KEYWORD = 'setErrorKeyword',
    SET_CAMPAIGN_PARTNERS = 'setCampaignPartners',
    SET_ERROR_ADD_CAMPAIGNS = 'setErrorAddCampaigns',
    SET_ERROR_MY_CAMPAIGNS = 'setErrorMyCampaigns',
    SET_MY_CAMPAIGNS_FILTERS = 'setMyCampaignsFilters',
    SET_MY_CAMPAIGNS_ORDER_BY = 'setMyCampaignsOrderBy',
    SET_MY_CAMPAIGNS_SORT_BY = 'setMyCampaignsSortBy',
    SET_MY_CAMPAIGNS_ROW_PER_PAGE = 'setMyCampaignsRowPerPage',
    SET_MY_CAMPAIGNS_FILTER_SHOULD_BE_RELOADED = 'setMyCampaignsFilterShouldBeReloaded',
    SET_ERROR_TRANSACTION = 'setErrorTransaction',
    SET_ERROR_PRICEPLANS = 'setErrorPriceplans',
    SET_SELECTED_PAYSYSTEM_ID = 'setSelectedPaysystemId',
    SET_ERROR_MY_PROFILE = 'setErrorMyProfile',
    SET_PRICE_PLANS = 'setPricePlans',
    SET_RATE_INSTALLS = 'setRateInstalls',
    SET_REVIEW_INSTALLS = 'setReviewInstalls',
    SET_CPI_INSTALLS = 'setCPIInstalls',
    SET_DELIVERY_TYPE = 'setDeliveryType',
    SET_DEEPLINK = 'setDeeplink',
    SET_CHECKED_TERMS = 'setCheckedTerms',
    SET_REVIEWS_ADD_CAMPAIGNS = 'setReviewsAddCampaigns',
    SET_CPI_BUDGET_ADD_CAMPAIGNS = 'setCpuBudgetAddCampaigns',
    SET_CPI_TARGET_ADD_CAMPAIGNS = 'setCpiBudgetAddCampaigns',
    SET_CPI_COUNTRY_ADD_CAMPAIGNS = 'setCpiCountryAddCampaigns',
    SET_CPI_HEADLINE1_ADD_CAMPAIGNS = 'setCpiHeadline1AddCampaigns',
    SET_CPI_HEADLINE2_ADD_CAMPAIGNS = 'setCpiHeadline2AddCampaigns',
    SET_CPI_DAYS_ADD_CAMPAIGNS = 'setCpiDaysAddCampaigns',
    SET_PARTNER_ADD_CAMPAIGNS = 'setPartnerAddCampaigns',
    SET_SERIALIZED_CHILD_KEYWORDS = 'setSerializedChildKeywords',
    SET_REVIEW_MODE_ADD_CAMPAIGNS = 'setReviewModeAddCampaigns',
    SET_BEFORE_CHANGE_STATUS_CAMPAIGN = 'setBeforeChangeStatusCampaign',
    SET_GENDER_ADD_CAMPAIGNS = 'setGenderAddCampaigns',
    REMOVE_REVIEWS_ADD_CAMPAIGNS = 'removeReviewsAddCampaigns',
    SET_ADD_CAMPAIGN = 'setAddCampaign',
    SET_PROMOTION_RULE = 'setPromotionRule',
    SET_RATE_TYPE = 'setRateType',
    SET_DESCRIPTION_ADD_CAMPAIGNS = 'setDescriptionAddCampaigns',
    SET_HQ_REVIEW_INSTALLS = 'setHQReviewInstalls',
    SET_SUGGEST_KEYWORDS = 'setSuggestKeyword',
    SET_MY_CAMPAIGNS = 'setMyCampaigns',
    SET_EXPORT_MY_CAMPAIGNS = 'setExportMyCampaigns',
    SET_MY_CAMPAIGNS_QUERY_PARAMS = 'setMyCampaignsQueryParams',
    SET_MY_CAMPAIGNS_ENTITY_EXPORT = 'setMyCampaignsEntityExport',
    SET_ROLE = 'setRole',
    SET_ROLES = 'setRoles',
    SET_COUNTRY = 'setCountry',
    SET_LANGUAGE = 'setLanguage',
    SET_APP = 'setApp',
    SET_APPSTORE = 'setAppstore',
    SET_APPS = 'setApps',
    SET_APPS_ANDROID = 'setAppsAndroid',
    SET_APPS_IOS = 'setAppsIos',
    SET_APPS_FAVORITE = 'setAppsFavorite',
    SET_APPS_BANNED = 'setAppsBanned',
    SET_KEYWORD_APPSTORE = 'setKeywordAppstore',
    SET_SEARCH_APPS = 'setSearchApps',
    SET_APPMAN_APP = 'setAppmanApp',
    SET_COUNTRIES = 'setCountries',
    SET_LANG = 'setLang',
    SET_UNIQUE_COUNTRIES = 'setUniqueCountries',
    SET_LANGUAGES = 'setLanguages',
    SET_PERMISSION_MODULE = 'setPermissionModule',
    SET_PERMISSION_MODULES = 'setPermissionModules',
    SET_KEYWORD = 'setKeyword',
    SET_KEYWORDS = 'setKeywords',
    SET_CLONED_KEYWORDS = 'setClonedKeywords',
    SET_EXPORT_KEYWORDS = 'setExportKeywords',
    SET_KEYWORDS_ADD = 'setKeywordsAdd',
    SET_PERMISSION_FUNCTION = 'setPermissionFunction',
    SET_PERMISSION_FUNCTIONS = 'setPermissionFunctions',
    SET_TOTAL_PAGES = 'setTotalPages',
    SET_FAVORITE_COUNT = 'setFavCount',
    SET_ALL_COUNT = 'setAllCount',
    SET_APPS_COUNT = 'setAppsCount',
    SET_COUNT_TOTAL = 'setCountTotal',
    SET_COUNT_ANDROID = 'setCountAndroid',
    SET_COUNT_IOS = 'setCountIos',
    SET_COUNT_FAVORITE = 'setCountFavorite',
    SET_COUNT_BANNED = 'setCountBanned',
    SET_APPS_LIMIT = 'setAppsLimit',
    SET_APPS_ANDROID_LIMIT = 'setAppsAndroidLimit',
    SET_APPS_IOS_LIMIT = 'setAppsIosLimit',
    SET_APPS_FAVORITE_LIMIT = 'setAppsFavoriteLimit',
    SET_APPS_BANNED_LIMIT = 'setAppsBannedLimit',
    SET_LOADING = 'setLoading',
    SET_IS_KEYWORD_LOADING = 'setIsKeywordLoading',
    SET_PAGE = 'setPage',
    SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
    SET_LAYOUT_CONFIG = 'setLayoutConfig',
    RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
    OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
    OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
    SET_TOP_APPS_BY_KEYWORD = 'setTopAppsByKeyword',
    SET_KEYWORD_RANK = 'setKeywordRank',
    SET_RANK_KEYWORD = 'setRankKeyword',
    SET_APP_ID = 'setAppId',
    SET_APP_KEYWORD_ID = 'setAppKeywordId',
    SET_COUNTRY_ID = 'setCountryId',
    SET_ALL_TRANSACTIONS = 'setAllTransactions',
    SET_TRANSACTIONS_EXPORT = 'setTransactionsExport',
    SET_ALL_PRICEPLANS = 'setAllPriceplans',
    SET_CURRENT_PRICEPLAN = 'setCurrentPlaceplan',
    SET_TOTAL_PAYMENTS = 'setTotalPayments',
    SET_CONSTANTS = 'setConstants',
    SET_USER_EMAIL_TEMPLATES = 'setUserEmailTemplates',
    SET_USER_EMAIL_TEMPLATES_LIST = 'setUserEmailTemplatesList',
    SET_LIST_PAYMENTS = 'setListPayments',
    SET_USER_DATA = 'setUserData',
    SET_PLATFORM_NEWS = 'setPlatformNews',
    SET_ERROR_WIDGETS = 'setErrorWidgets',
    SET_FAQS = 'setFAQs',
    SET_CAMPAIGNS_TOTAL = 'setCampaignsTotal',
    SET_GEOS = 'setGEOs',
    SET_TOP_REGIONS = 'setTopRegions',
    SET_MY_APPS = 'setMyApps',
    SET_ERROR_STATISTICS = 'setErrorStatistics',
    SET_STATISTICS_PRODUCTS = 'setProducts',
    SET_STATISTICS_KEYWORDS = 'setStatisticsKeywords',
    SET_STATISTICS_CAMPAIGNS = 'setStatisticsCampaigns',
    SET_STATISTICS_PRODUCTS_EXPORT = 'setStatisticsProductsExport',
    SET_STATISTICS_KEYWORDS_EXPORT = 'setStatisticsKeywordsExport',
    SET_STATISTICS_CAMPAIGNS_EXPORT = 'setStatisticsCampaignsExport',
    SET_STATISTICS_PRODUCTS_QUERY_PARAMS = 'setStatisticsProductsQueryParams',
    SET_STATISTICS_KEYWORDS_QUERY_PARAMS = 'setStatisticsKeywordsQueryParams',
    SET_STATISTICS_CAMPAIGNS_QUERY_PARAMS = 'setStatisticsCampaignsQueryParams',
    SET_MY_CAMPAIGNS_TOTAL = 'setMyCampaignsTotal',
    SET_MY_CAMPAIGNS_CURRENT_PAGE = 'setMyCampaignsCurrentPage',
    SET_MY_CAMPAIGNS_LAST_PAGE = 'setMyCampaignsLastPage',
    SET_CURRENT_PAGE = 'setCurrentPage',
    SET_LAST_PAGE = 'setLastPage',
    SET_MY_CAMPAIGNS_COUNTS = 'setMyCampaignsCounts',
    SET_UPDATE_CAMPAIGNS = 'setUpdateCampaigns',
    SET_PAYMENT_CONFIGURATIONS = 'setPaymentConfigurations',
    SET_PAYMENT_ERRORS = 'setPaymentErrors',
    SET_CURRENT_CAMPAIGN = 'setCurrentCampaign',
    SET_CAMPAIGNS_WIDGETS = 'setCampaignsWidgets',
    SET_CAMPAIGN_ID_TOP_FILTER = 'setCampaignIdTopFilter',
    SET_LOADED_CPI_MODAL_IDS = 'setLoadedCpiModalIds',
    SET_PAYSYSTEMS = 'setPaysystems',
    SET_BONUS = 'setBonus',
    SET_OPTIONS = 'setOptions',
    SET_LOADED_CAMPAIGNS = 'setLoadedCampaigns',
    ADD_CAMPAIGNS_SHOW_ALL_ORDERS = 'addCampaignsShowAllOrders',
    SET_CAMPAIGNS_SHOW_ALL_ORDERS = 'setCampaignsShowAllOrders',
    UPDATE_LOADED_CAMPAIGN = 'updateLoadedCampaign',
    SET_CAMPAIGNS_DATA = 'setCampaignsData',
    SET_PINNED_NOTIFICATION = 'setPinnedNotification',
    SET_PAYMENT_LINK = 'setPaymentLink',
    SET_TOTAL_TRANSACTIONS = 'setTotalTransactions',
    SET_TRANSACTIONS_PAGE = 'setTransactionsPage',
    SET_TRANSACTIONS_ROWS = 'setTransactionsRows',
    SET_TRANSACTIONS_SORT_BY = 'setTransactionsRowsSortBy',
    SET_TRANSACTIONS_SORT_TYPE = 'setTransactionsRowsSortType',
    SET_TRANSACTIONS_TYPE = 'setTransactionsType',
    SET_TRANSACTIONS_APPSTORE = 'setTransactionsAppstore',
    SET_TRANSACTIONS_PACKAGE = 'setTransactionsPackage',
    SET_TRANSACTIONS_SEARCH = 'setTransactionsSearch',
    SET_TRANSACTIONS_LIMIT = 'setTransactionsLimit',
    SET_TRANSACTIONS_CURRENT_PAGE = 'setTransactionsCurrentPage',
    SET_TRANSACTIONS_LAST_PAGE = 'setTransactionsLastPage',
    SET_TRANSACTIONS_DATE = 'setTransactionsDate',
    SET_TOTAL_BILLINGS = 'setTotalBillings',
    SET_BILLINGS_PAGE = 'setBillingsPage',
    SET_BILLINGS_ROWS = 'setBillingsRows',
    SET_BILLINGS_SORT_TYPE = 'setBillingsSortType',
    SET_BILLINGS_SORT_BY = 'setBillingsSortBy',
    SET_BILLINGS_TYPE = 'setBillingsType',
    SET_BILLINGS_APPSTORE = 'setBillingsAppstore',
    SET_BILLINGS_PACKAGE = 'setBillingsPackage',
    SET_BILLINGS_SEARCH = 'setBillingsSearch',
    SET_BILLINGS_LIMIT = 'setBillingsLimit',
    SET_BILLINGS_CURRENT_PAGE = 'setBillingsCurrentPage',
    SET_BILLINGS_LAST_PAGE = 'setBillingsLastPage',
    SET_MANAGER = 'setManager',
    SET_MANAGERS = 'setManagers',
    SET_NOTICE_TEMPLATES = 'setNoticeTemplates',
    SET_CURRENT_USER_NOTICE_DATA = 'setCurrentUserNoticeData',
    SET_ERRORS_MANAGER = 'setErrorsManager',
    SET_NOTICES = 'setNotices',
    ADD_NOTICES = 'addNotices',
    SET_ERROR_ACCESS_LISTS = 'setErrorAccessLists',
    SET_TOTAL_ACCESS_LISTS = 'setTotalAccessLists',
    SET_ACCESS_LISTS_CURRENT_PAGE = 'setAccessListsCurrentPage',
    SET_ACCESS_LISTS_LAST_PAGE = 'setAccessListsLastPage',
    SET_ALL_ACCESS_LISTS = 'setAllAccessLists',
    SET_ACCESS_LISTS_ROWS = 'setAccessListsRows',
    SET_ACCESS_LISTS_TYPE = 'setAccessListsType',
    SET_ACCESS_LISTS_SEARCH = 'setAccessListsSearch',
    SET_ACCESS_LISTS_CATEGORY = 'setAccessListsCategory',
    SET_ACCESS_LISTS_ORDER_BY = 'setAccessListsOrderBy',
    SET_ACCESS_LISTS_SORT_BY = 'setAccessListsSortBy',
    SET_UNREAD_NOTICES = 'setUnreadNotices',
    SET_ERROR_USER_LISTS = 'setErrorUserLists',
    SET_TOTAL_USER_LISTS = 'setTotalUserLists',
    SET_USER_LISTS_CURRENT_PAGE = 'setUserListsCurrentPage',
    SET_USER_LISTS_LAST_PAGE = 'setUserListsLastPage',
    SET_ALL_USER_LISTS = 'setAllUserLists',
    SET_USER_LISTS_EXPORT = 'setUserListsExport',
    SET_USER_LIST = 'setUserList',
    SET_USER_LISTS_ROWS = 'setUserListsRows',
    SET_CURRENT_USER_ID = 'setCurrentUserId',
    SET_USER_LISTS_SEARCH = 'setUserListsSearch',
    SET_USER_LISTS_ORDER_BY = 'setUserListsOrderBy',
    SET_USER_LISTS_SORT_BY = 'setUserListsSortBy',
    SET_USER_LISTS_QUERY_PARAMS = 'SET_USER_LISTS_QUERY_PARAMS',
    SET_USER_LISTS_COLUMN_SEARCH_FILTER = 'setUserListsColumnSearchFilter',
    SET_USER_LISTS_ROLE = 'setUserListsRole',
    SET_USER_LISTS_PRICEPLAN = 'setUserListsPriceplan',
    SET_COUNTRIES_STATUS = 'setCountriesStatus',
    SET_CONSTANTS_STATUS = 'setConstantsStatus',
    SET_LANGUAGES_STATUS = 'setLanguagesStatus',
    SET_PRICEPLANS_STATUS = 'setPriceplansStatus',
    SET_CURRENT_PRICEPLAN_STATUS = 'setCurrentPriceplanStatus',
    SET_LEGACY_BALANCE_STATUS = 'setLegacyBalanceStatus',
    SET_APPS_STATUS = 'setAppsStatus',
    SET_NOTICES_STATUS = 'setNoticesStatus',
    CLEAR_APP_STORE = 'clearAppStore',
    CLEAR_ACCESS_LIST_STORE = 'clearAccessListStore',
    CLEAR_ADD_CAMPAIGNS_STORE = 'clearAddCampaignsStore',
    CLEAR_AUTH_STORE = 'clearAuthStore',
    CLEAR_BODY_STORE = 'clearBodyStore',
    CLEAR_BREADCRUMBS_STORE = 'clearBreadcrumbsStore',
    CLEAR_CONFIG_STORE = 'clearConfigStore',
    CLEAR_COUNTRY_STORE = 'clearCountryStore',
    CLEAR_KEYWORD_STORE = 'clearKeywordStore',
    CLEAR_LANGUAGE_STORE = 'clearLanguageStore',
    CLEAR_MANAGER_STORE = 'clearManagerStore',
    CLEAR_MY_PROFILE_STORE = 'clearMyProfileStore',
    CLEAR_PAYMENTS_SYSTEM_STORE = 'clearPaymentsSystemStore',
    CLEAR_PERMISSION_STORE = 'clearPermissionStore',
    CLEAR_PRICEPLANS_STORE = 'clearPriceplansStore',
    CLEAR_ROLE_STORE = 'clearRoleStore',
    CLEAR_STARTING_STORE = 'clearStartingStore',
    CLEAR_STATISTICS_STORE = 'clearStatisticsStore',
    CLEAR_TOP_UP_BALANCE_STORE = 'clearTopUpBalanceStore',
    CLEAR_TRANSACTION_STORE = 'clearTransactionStore',
    CLEAR_USER_DETAILS_PAYMENTS_STORE = 'clearUserDetailsPaymentsStore',
    CLEAR_USER_LIST_STORE = 'clearUserListStore',
    CLEAR_WIDGETS_STORE = 'clearWidgetsStore',
    MY_CAMPAIGNS_CLEAR_STORE = 'MyCampaignsClearStore',
    SET_USER_DETAILS_PAYMENTS_ROWS = 'setUserDetailsPaymentsRows',
    SET_ERROR_USER_DETAILS_PAYMENTS = 'setErrorUserDetailsPayments',
    SET_ALL_USER_DETAILS_PAYMENTS = 'setAllUserDetailsPayments',
    SET_TOTAL_USER_DETAILS_PAYMENTS = 'setTotalUserDetailsPayments',
    SET_USER_DETAILS_PAYMENTS_CURRENT_PAGE = 'setUserDetailsPaymentsCurrentPage',
    SET_USER_DETAILS_PAYMENTS_LAST_PAGE = 'setUserDetailsPaymentsLastPage',
    SET_USER_DETAILS_PAYMENTS_SEARCH = 'setUserDetailsPaymentsSearch',
    SET_USER_DETAILS_PAYMENTS_ORDER_BY = 'setUserDetailsPaymentsOrderBy',
    SET_USER_DETAILS_PAYMENTS_SORT_BY = 'setUserDetailsPaymentsSortBy',
    SET_USER_DETAILS_PAYMENTS_BALANCE_TYPE = 'setUserDetailsPaymentsBalanceType',
    CLEAR_ADMIN_PAYMENTS_SYSTEM_STORE = 'clearAdminPaymentsSystemStore',
    SET_ADMIN_PAYSYSTEMS = 'setAdminPaysystems',
    SET_ADMIN_TOTAL_PAYSYSTEMS = 'setAdminTotalPaysystems',
    SET_ADMIN_PAYMENT_ERRORS = 'setAdminPaymentErrors',
    SET_USERS_REFERRALS = 'setUsersReferrals',
    SET_USERS_REFERRALS_TOTAL = 'setUsersReferralsTotal',
    SET_USERS_PAYOUTS = 'setUsersPayouts',
    SET_WIDGETS_REFERRALS = 'setWidgetsReferrals',
    SET_ERROR_REFERRAL = 'setErrorReferral',
    SET_USERS_REFERRALS_LAST_PAGE = 'setUsersReferralsLastPage',
    SET_USERS_REFERRALS_CURRENT_PAGE = 'setUsersReferralsCurrentPage',
    SET_USERS_REFERRALS_ROWS_PER_PAGE = 'setUsersReferralsRowsPerPage',
    SET_USERS_REFERRALS_ORDER_BY = 'setUsersReferralsOrderBy',
    SET_USERS_REFERRALS_SORT_BY = 'setUsersReferralsSortBy',
    SET_USERS_REFERRALS_SEARCH = 'setUsersReferralsSearch',
    SET_USERS_REFERRALS_QUERY_PARAMS = 'setUsersReferralsQueryParams',
    SET_USERS_REFERRALS_EXPORT = 'setUsersReferralsExport',
    SET_ADMIN_REFLINKS_ROWS = 'setAdminReflinksRows',
    SET_ERROR_ADMIN_REFLINKS = 'setErrorAdminReflinks',
    SET_ALL_ADMIN_REFLINKS = 'setAllAdminReflinks',
    SET_TOTAL_ADMIN_REFLINKS = 'setTotalAdminReflinks',
    SET_ADMIN_REFLINKS_CURRENT_PAGE = 'setAdminReflinksCurrentPage',
    SET_ADMIN_REFLINKS_LAST_PAGE = 'setAdminReflinksLastPage',
    SET_ADMIN_REFLINKS_SEARCH = 'setAdminReflinksSearch',
    SET_ADMIN_REFLINKS_CATEGORY = 'setAdminReflinksCategory',
    SET_ADMIN_REFLINKS_ORDER_BY = 'setAdminReflinksOrderBy',
    SET_ADMIN_REFLINKS_SORT_BY = 'setAdminReflinksSortBy',
    SET_ADMIN_REFLINKS_QUERY_PARAMS = 'setAdminReflinksQueryParams',
    SET_ADMIN_REFLINKS_EXPORT = 'setAdminReflinksExport',
    SET_CURRENT_ADMIN_REFLINKS_ID = 'setCurrentAdminReflinksId',
    CLEAR_ADMIN_REFLINKS_STORE = 'clearAdminReflinksStore',
    SET_ERROR_RESELLER_PROGRAM = 'setErrorResellerProgram',
    SET_USER_ORDER_COUNTS = 'setUserOrderCounts',
    SET_USER_ORDER_TOTAL = 'setUserOrderTotal',
    SET_USER_ORDER_LAST_PAGE = 'setUserOrderLastPage',
    SET_LOADED_USER_ORDERS = 'setLoadedUserOrders',
    SET_UPDATE_USER_ORDER = 'setUpdateUserOrder',
    SET_USER_ORDER_QUERY_PARAMS = 'setUserOrderQueryParams',
    SET_USER_ORDER_EXPORT = 'setUserOrderExport',
    SET_USER_ORDER_CPI_CURRENT_ID = 'setUserOrderCpiCurrentId',
    SET_CURRENT_USER_ORDER = 'setCurrentUserOrder',
    SET_USER_ORDER_PARTNERS = 'setUserOrderPartners',
    SET_CPI_ACCOUNTS = 'setCpiAccounts',
    SET_USER_ORDER_CURRENT_PAGE = 'setUserOrderCurrentPage',
    SET_USER_ORDER_AFTER_CHANGE_OBJECT = 'setUserOrderAfterChangeObject',
    SET_BEFORE_CHANGE_STATUS_USER_ORDER = 'setBeforeChangeStatusUserOrder',
    UPDATE_LOADED_USER_ORDER = 'updateLoadedUserOrder',
    SET_LOADED_USER_ORDER = 'SET_LOADED_USER_ORDER',
    SET_USER_ORDER = 'setUserOrder',
    SET_ERROR_USER_ORDER = 'setErrorUserOrder',
    SET_ALERT_STATUS = 'setAlertStatus',
    SET_ALERT_TYPE = 'setAlertType',
    SET_ALERT_MESSAGE = 'setAlertMessage',
    SET_MILLISECONDS_TO_CLOSE = 'setMillisecondsToClose',
    SET_CLOSE_ONLY_ON_CLICK = 'setCloseOnlyOnClick',
    SET_DASHBOARD_START_UNREAD_NOTICES = 'setDashboardStartUnreadNotices',
    SET_ERROR_NOTIFICATIONS = 'setErrorNotifications',
    SET_CHAT_PAGE_NOTIFICATIONS = 'setChatPageNotifications',
    SET_NOTIFICATIONS = 'setNotifications',
    SET_CORE_SETTING_PAYSYSTEM_SEARCH = 'setCoreSettingPaysystemSearch',
    SET_PROMOTE_KEYWORDS = 'setPromoteKeywords',
    SET_LEGACY_BALANCE = 'setLegacyBalance',
    SET_RUNNING_REQUESTS = 'setRunningRequests',
    SET_LAST_ADDED_APP = 'setLastAddedApp',

    SET_VENDORS_ROWS = 'setVendorsRows',
    SET_ERROR_VENDORS = 'setErrorVendors',
    SET_ALL_VENDORS = 'setAllVendors',
    SET_TOTAL_VENDORS = 'setTotalVendors',
    SET_VENDORS_CURRENT_PAGE = 'setVendorsCurrentPage',
    SET_VENDORS_LAST_PAGE = 'setVendorsLastPage',
    SET_VENDORS_ORDER_BY = 'setVendorsOrderBy',
    SET_VENDORS_SORT_BY = 'setVendorsSortBy',
    SET_VENDORS_CURRENT_COUNTRY = 'setVendorsCurrentCountry',
    SET_VENDORS_CURRENT_VENDOR = 'setVendorsCurrentVendor',
    SET_VENDORS_LIST = 'setVendorsList',
    SET_VENDORS_EXPORT = 'setVendorsExport',
    SET_VENDORS_RAW_EXPORT = 'setVendorsRawExport',
    SET_VENDORS_QUERY_PARAMS = 'setVendorsQueryParams',
    SET_VENDORS_CURRENT_STORE = 'setVendorsCurrentStore',
    SET_VENDORS_CURRENT_PRODUCT = 'setVendorsCurrentProduct',

    SET_USER_PAYMENTS_ROWS = 'setUserPaymentsRows',
    SET_ERROR_USER_PAYMENTS = 'setErrorUserPayments',
    SET_ALL_USER_PAYMENTS = 'setAllUserPayments',
    SET_TOTAL_USER_PAYMENTS = 'setTotalUserPayments',
    SET_USER_PAYMENTS_CURRENT_PAGE = 'setUserPaymentsCurrentPage',
    SET_USER_PAYMENTS_LAST_PAGE = 'setUserPaymentsLastPage',
    SET_USER_PAYMENTS_SEARCH = 'setUserPaymentsSearch',
    SET_USER_PAYMENTS_ORDER_BY = 'setUserPaymentsOrderBy',
    SET_USER_PAYMENTS_SORT_BY = 'setUserPaymentsSortBy',
    SET_USER_PAYMENTS_PRICEPLAN = 'setUserPaymentsPriceplan',
    SET_USER_PAYMENTS_BALANCE_TYPE = 'setUserPaymentsBalanceType',
    SET_USER_PAYMENTS_PAYMENT_TYPE = 'setUserPaymentsPaymentType',
    SET_USER_PAYMENTS_QUERY_PARAMS = 'setUserPaymentsQueryParams',
    SET_USER_PAYMENTS_EXPORT = 'setUserPaymentsExport',
    SET_USER_PAYMENTS_COLUMN_SEARCH_FILTER = 'setUserPaymentsColumnSearchFilter',

    SET_ERROR_USER_DETAILS_MESSAGES = 'setErrorUserDetailsMessages',
    SET_ALL_USER_DETAILS_MESSAGES = 'setAllUserDetailsMessages',
    SET_TOTAL_USER_DETAILS_MESSAGES = 'setTotalUserDetailsMessages',
    SET_USER_DETAILS_MESSAGES_SEARCH = 'setUserDetailsMessagesSearch',
    SET_USER_DETAILS_MESSAGES_MANAGER = 'setUserDetailsMessagesManager',
    SET_USER_DETAILS_MESSAGES_CAMPAIGN = 'setUserDetailsMessagesCampaign',

    SET_USER_APPS_ROWS = 'setUserAppsRows',
    SET_USER_APPS_COLUMN_SEARCH_FILTER = 'setUserAppsColumnSearchFilter',
    SET_USER_APPS_SEARCH = 'setUserAppsSearch',
    SET_USER_APPS_STORE = 'setUserAppsStore',
    SET_USER_APPS_ALL_APPS_FILTER = 'setUserAppsAllAppsFilter',
    SET_ERROR_USER_APPS = 'setErrorUserApps',
    SET_USER_APPS_CURRENT_PAGE = 'setUserAppsCurrentPage',
    SET_USER_APPS_LAST_PAGE = 'setUserAppsLastPage',
    SET_USER_APPS_ORDER_BY = 'setUserAppsOrderBy',
    SET_USER_APPS_SORT_BY = 'setUserAppsSortBy',
    SET_USER_APPS_EXPORT = 'setUserAppsExport',
    SET_USER_APPS_QUERY_PARAMS = 'setUserAppsQueryParams',
    SET_TOTAL_USER_APPS = 'setTotalUserApps',
    SET_ALL_USER_APPS = 'setAllUserApps',

    SET_ADMIN_EMAIL_TEMPLATES = 'setAdminEmailTemplates',
    SET_ADMIN_EMAIL_TEMPLATES_ERRORS = 'setAdminEmailTemplatesErrors',
    SET_ADMIN_EMAIL_TEMPLATE_SEARCH = 'setAdminEmailTemplateSearchs',
    SET_ADMIN_TOTAL_EMAIL_TEMPLATES = 'setAdminTotalEmailTemplates',

    SET_SALES_REPORT_MY_CLIENTS_ROWS = 'setSalesReportMyClientsRows',
    SET_ERROR_SALES_REPORT_MY_CLIENTS = 'setErrorSalesReportMyClients',
    SET_ALL_SALES_REPORT_MY_CLIENTS = 'setAllSalesReportMyClients',
    SET_SALES_REPORT_MY_CLIENTS_EXPORT = 'setSalesReportMyClientsExport',
    SET_TOTAL_SALES_REPORT_MY_CLIENTS = 'setTotalSalesReportMyClients',
    SET_SALES_REPORT_MY_CLIENTS_CURRENT_PAGE = 'setSalesReportMyClientsCurrentPage',
    SET_SALES_REPORT_MY_CLIENTS_LAST_PAGE = 'setSalesReportMyClientsLastPage',
    SET_SALES_REPORT_MY_CLIENTS_SEARCH = 'setSalesReportMyClientsSearch',
    SET_SALES_REPORT_MY_CLIENTS_PRICEPLAN = 'setSalesReportMyClientsPriceplan',
    SET_SALES_REPORT_MY_CLIENTS_ORDER_BY = 'setSalesReportMyClientsOrderBy',
    SET_SALES_REPORT_MY_CLIENTS_SORT_BY = 'setSalesReportMyClientsSortBy',
    SET_SALES_REPORT_MY_CLIENTS_QUERY_PARAMS = 'setSalesReportMyClientsQueryParams',
    SET_SALES_REPORT_MY_CLIENTS_OFFER_STATUS = 'setSalesReportMyClientsOfferStatus',

    SET_SALES_REPORT_NEW_CLIENTS_ROWS = 'setSalesReportNewClientsRows',
    SET_ERROR_SALES_REPORT_NEW_CLIENTS = 'setErrorSalesReportNewClients',
    SET_ALL_SALES_REPORT_NEW_CLIENTS = 'setAllSalesReportNewClients',
    SET_TOTAL_SALES_REPORT_NEW_CLIENTS = 'setTotalSalesReportNewClients',
    SET_SALES_REPORT_NEW_CLIENTS_CURRENT_PAGE = 'setSalesReportNewClientsCurrentPage',
    SET_SALES_REPORT_NEW_CLIENTS_LAST_PAGE = 'setSalesReportNewClientsLastPage',
    SET_SALES_REPORT_NEW_CLIENTS_ORDER_BY = 'setSalesReportNewClientsOrderBy',
    SET_SALES_REPORT_NEW_CLIENTS_SORT_BY = 'setSalesReportNewClientsSortBy',
    SET_EMPTY_LOAD_NOTICE_DATA = 'setEmptyLoadNoticeData',

    SET_SALES_REPORT_STATISTIC_ERRORS = 'setSalesReportStatisticErrors',
    SET_SALES_REPORT_STATISTIC_BY_PAYMENTS = 'setSalesReportStatisticByPayments',
    SET_SALES_REPORT_STATISTIC_BY_STATUSES = 'setSalesReportStatisticByStatuses',
    SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS = 'setSalesReportStatisticLastPayments',
    SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_TOTAL = 'setSalesReportStatisticLastPaymentsTotal',
    SET_SALES_REPORT_STATISTIC_TOTALS = 'setSalesReportStatisticTotalsAction',
    SET_SALES_REPORT_STATISTIC_TOTAL_CLIENTS = 'setSalesReportStatisticTotalClients',
    SET_SALES_REPORT_STATISTIC_ACTIVE_CLIENTS = 'setSalesReportStatisticActiveClients',
    SET_SALES_REPORT_STATISTIC_PAYMENTS_COMMITTED = 'setSalesReportStatisticPaymentsCommitted',
    SET_SALES_REPORT_STATISTIC_MY_PAYMENTS_TOTAL = 'setSalesReportStatisticMyPaymentsTotal',
    SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_CURRENT_PAGE = 'setSalesReportStatisticLastPaymentsCurrentPage',
    SET_SALES_REPORT_STATISTIC_LAST_PAYMENTS_LAST_PAGE = 'setSalesReportStatisticLastPaymentsLastPage',

    SET_ADMIN_PRODUCTS_ERROR = 'setAdminProductsError',
    SET_ADMIN_PRODUCTS_TOTAL_PRODUCTS = 'setAdminProductsTotalProducts',
    SET_ADMIN_PRODUCTS_APPSTORE = 'setAdminProductsAppstore',
    SET_ADMIN_PRODUCTS_PRODUCTS = 'setAdminProductsProducts',

    SET_CHECKBOX_VALUES = 'setCheckboxValues',
    SET_CHECKBOX_UNLIMITED = 'setCheckboxUnlimited',

    SET_ADMIN_MARKETING_ACTIONS_ROWS = 'setAdminMarketingActionsRows',
    SET_ERROR_ADMIN_MARKETING_ACTIONS = 'setErrorAdminMarketingActions',
    SET_ALL_ADMIN_MARKETING_ACTIONS = 'setAllAdminMarketingActions',
    SET_TOTAL_ADMIN_MARKETING_ACTIONS = 'setTotalAdminMarketingActions',
    SET_ADMIN_MARKETING_ACTIONS_CURRENT_PAGE = 'setAdminMarketingActionsCurrentPage',
    SET_ADMIN_MARKETING_ACTIONS_LAST_PAGE = 'setAdminMarketingActionsLastPage',
    SET_ADMIN_MARKETING_ACTIONS_SEARCH = 'setAdminMarketingActionsSearch',
    SET_ADMIN_MARKETING_ACTIONS_ORDER_BY = 'setAdminMarketingActionsOrderBy',
    SET_ADMIN_MARKETING_ACTIONS_SORT_BY = 'setAdminMarketingActionsSortBy',
    SET_ADMIN_MARKETING_ACTIONS_QUERY_PARAMS = 'setAdminMarketingActionsQueryParams',
    SET_ADMIN_MARKETING_ACTIONS_EXPORT = 'setAdminMarketingActionsExport',
    SET_ADMIN_MARKETING_ACTIONS_COUNTRY = 'setAdminMarketingActionsCountry',
    SET_ADMIN_MARKETING_ACTIONS_CURRENT_ID = 'setAdminMarketingActionsCurrentId',
    SET_ADMIN_MARKETING_ACTIONS_FILTERS = 'setAdminMarketingActionsFilters',
    SET_MARKETING_ACTIONS_ERRORS = 'setMarketingActionsErrors',
    SET_MARKETING_ACTIONS_EXISTS = 'setMarketingActionsExists',
    SET_MARKETING_ACTIONS_TEXT = 'setMarketingActionsText',
    SET_MARKETING_ACTIONS_TYPE = 'setMarketingActionsType',
    SET_MARKETING_ACTIONS_THRESHOLDS = 'setMarketingActionsThresholds',

    SET_CLIENTS_REPORT_ROWS = 'setClientsReportRows',
    SET_ERROR_CLIENTS_REPORT = 'setErrorClientsReport',
    SET_ALL_CLIENTS_REPORT = 'setAllClientsReport',
    SET_CLIENTS_REPORT_EXPORT = 'setClientsReportExport',
    SET_TOTAL_CLIENTS_REPORT = 'setTotalClientsReport',
    SET_CLIENTS_REPORT_CURRENT_PAGE = 'setClientsReportCurrentPage',
    SET_CLIENTS_REPORT_LAST_PAGE = 'setClientsReportLastPage',
    SET_CLIENTS_REPORT_SEARCH = 'setClientsReportSearch',
    SET_CLIENTS_REPORT_ORDER_BY = 'setClientsReportOrderBy',
    SET_CLIENTS_REPORT_SORT_BY = 'setClientsReportSortBy',
    SET_CLIENTS_REPORT_QUERY_PARAMS = 'setClientsReportQueryParams',
    SET_CLIENTS_REPORT_COUNTRY = 'setClientsReportCountry',
    SET_CLIENTS_REPORT_MANAGER = 'setClientsReportManager',
    SET_CLIENTS_REPORT_PRICEPLAN = 'setClientsReportPriceplan',

    SET_USER_DETAILS_TRANSACTIONS_ROWS = 'setUserDetailsTransactionsRows',
    SET_ERROR_USER_DETAILS_TRANSACTIONS = 'setErrorUserDetailsTransactions',
    SET_ALL_USER_DETAILS_TRANSACTIONS = 'setAllUserDetailsTransactions',
    SET_TOTAL_USER_DETAILS_TRANSACTIONS = 'setTotalUserDetailsTransactions',
    SET_USER_DETAILS_TRANSACTIONS_CURRENT_PAGE = 'setUserDetailsTransactionsCurrentPage',
    SET_USER_DETAILS_TRANSACTIONS_LAST_PAGE = 'setUserDetailsTransactionsLastPage',
    SET_USER_DETAILS_TRANSACTIONS_SEARCH = 'setUserDetailsTransactionsSearch',
    SET_USER_DETAILS_TRANSACTIONS_ORDER_BY = 'setUserDetailsTransactionsOrderBy',
    SET_USER_DETAILS_TRANSACTIONS_SORT_BY = 'setUserDetailsTransactionsSortBy',
    SET_USER_DETAILS_TRANSACTIONS_TYPE = 'setUserDetailsTransactionsType',
    SET_USER_DETAILS_TRANSACTIONS_QUERY_PARAMS = 'setUserDetailsTransactionsQueryParams',
    SET_USER_DETAILS_TRANSACTIONS_EXPORT = 'setUserDetailsTransactionsExport',
    SET_USER_DETAILS_APPS = 'setUserDetailsApps',

    SET_USER_ORDERS_STATUSES = 'setUserOrdersStatuses',
    SET_USER_ORDER_COLUMN_SEARCH_FILTER = 'setUserOrderColumnSearchFilter',
    SET_TOTAL_USER_ORDERS_STATUSES = 'setTotalUserOrdersStatuses',
    SET_PAGE_STATUSES = 'setPageStatuses',
    SET_FILTER_STATUSES = 'setFilterStatuses',
    SET_LIMIT_STATUSES = 'setLimitStatuses',
    SET_ORDER_BY_STATUSES = 'setOrderByStatuses',
    SET_SORT_BY_STATUSES = 'setSortByStatuses',

    SET_USER_DETAILS_REFERRALS_ROWS = 'setUserDetailsReferralsRows',
    SET_ERROR_USER_DETAILS_REFERRALS = 'setErrorUserDetailsReferrals',
    SET_ALL_USER_DETAILS_REFERRALS = 'setAllUserDetailsReferrals',
    SET_TOTAL_USER_DETAILS_REFERRALS = 'setTotalUserDetailsReferrals',
    SET_USER_DETAILS_REFERRALS_CURRENT_PAGE = 'setUserDetailsReferralsCurrentPage',
    SET_USER_DETAILS_REFERRALS_LAST_PAGE = 'setUserDetailsReferralsLastPage',
    SET_USER_DETAILS_REFERRALS_SEARCH = 'setUserDetailsReferralsSearch',
    SET_USER_DETAILS_REFERRALS_ORDER_BY = 'setUserDetailsReferralsOrderBy',
    SET_USER_DETAILS_REFERRALS_SORT_BY = 'setUserDetailsReferralsSortBy',
    SET_USER_DETAILS_REFERRALS_QUERY_PARAMS = 'setUserDetailsReferralsQueryParams',
    SET_USER_DETAILS_REFERRALS_EXPORT = 'setUserDetailsReferralsExport',

    SET_GOOGLE_ADS_ROWS = 'setGoogleAdsRows',
    SET_ERROR_GOOGLE_ADS = 'setErrorGoogleAds',
    SET_ALL_GOOGLE_ADS = 'setAllGoogleAds',
    SET_GOOGLE_ADS_EXPORT = 'setGoogleAdsExport',
    SET_TOTAL_GOOGLE_ADS = 'setTotalGoogleAds',
    SET_GOOGLE_ADS_CURRENT_PAGE = 'setGoogleAdsCurrentPage',
    SET_GOOGLE_ADS_LAST_PAGE = 'setGoogleAdsLastPage',
    SET_GOOGLE_ADS_SEARCH = 'setGoogleAdsSearch',
    SET_GOOGLE_ADS_ORDER_BY = 'setGoogleAdsOrderBy',
    SET_GOOGLE_ADS_SORT_BY = 'setGoogleAdsSortBy',
    SET_GOOGLE_ADS_QUERY_PARAMS = 'setGoogleAdsQueryParams',
    SET_DONT_EXISTS_REF_CODES_GOOGLE_ADS = 'sEtDontExistsRefCodesGoogleAds',
    SET_FROM_GOOGLE_ADS = 'setFromGoogleAds',
    SET_TO_GOOGLE_ADS = 'setToGoogleAds',
    SET_TYPE_GOOGLE_ADS = 'setTypeGoogleAds'
}

export { Actions, Mutations };
