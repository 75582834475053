const topUpBalance = {
  topUpBalance: 'Top-up balance',
  pleaseEnterPaymentAmount: 'Please enter a payment amount',
  depositAmount: 'Deposit amount',
  bonus: 'bonus',
  pleaseSelectYourPreferredPaymentSystem: 'Please select your preferred payment system',
  addFundsBy: 'Add funds by',
  usdtBitcoinEthereum: 'USDT (Tether), Bitcoin, Ethereum',
  or: 'or',
  cryptocurrencyPaymentWillBeAutomaticallyAdded: 'Please note, cryptocurrency payment will be automatically added to your account after we receive 3 or more transaction confirmations.',
  makePayment: 'Make payment',
  weApplyPaymentBonusForEachSingleTransaction: 'We apply payment bonus for each single transaction, depending on its amount (excluding PayPal):',
  moreThan: 'more than',
  forExample: 'For example, if you pay 2000$ - you will receive 2000+200 (10% bonus) = 2200$, or if you pay 7000$ - you receive 7000+1050 (15% as bonus) = 8050$!',
  inCaseOfRefundRequest: 'In case of a refund request we will return the amount of your payment without all bonuses!',
  bonusValidFor: 'Bonus valid for 120 days',
  ByAddingFundsToYourAccount: 'By adding funds to your account, you automatically accept our',
  refundAndChargebackPolicy: 'Refund and Chargeback policy.',
  ifYouNeedAnInvoice: 'To request an invoice, please email {email} with the amount and payee details, or contact the Support Team through live chat.',
  withAmountAndYayeeDetails: 'with amount and payee details.',
  billingHistory: 'Billing history',
  paymentsOf: 'payments of',
  creditCard: 'Credit Card',
  googlePay: 'Google Pay',
  applePay: 'Apple Pay',
  payPal: 'PayPal',
  usdt: 'USDT (Tether)',
  bitcoin: 'Bitcoin',
  ethereum: 'Ethereum',
  usdCoin: 'USD Coin',
  binancePay: 'Binance Pay',
  alipay: 'Alipay',
  payoneer: 'Payoneer',
  wise: 'Wise',
  weAccept: 'We accept',
  paymentsOnlyIn: 'payments only in',
  manual: 'manual',
  mode: 'mode',
  toTopUpYourBalanceWith: 'To top-up your balance with',
  pleaseContactSupportForTransactionDetails: 'please contact support for transaction details',
  wireBankTransfer: 'Wire bank transfer',
  wireBank: 'Wire bank',
  wireTransfer: 'Wire transfer',
  pleaseContactSupportForInvoiceAndTransactionDetails: 'please contact support for invoice and transaction details',
  minimumPaymentAmountIs: 'Minimum payment amount is',
  maximumPaymentAmountIs: 'Maximum payment amount is',
  transactionFee: 'transaction fee',
  paymentIsCanceled: 'Payment is canceled',
  topUpYourBalanceWithPayPal: 'Top-up your balance with PayPal',
  dueYoNewPaypalRestrictions: "Due to new PayPal's restrictions, we have to increase transaction fee for all payments via <b>PayPal</b> highly. It's <b>{fee}%</b> now.",
  alsoWeveRemovedAllRegularBonuses: "We've <b>removed</b> all regular bonuses for payments greater than 1000$ processed with PayPal. Please use Credit cards or alternative ways of balance top-up to recieve these bonuses.",
  topUpYourBalanceWithPayoneer: 'Top-up your balance with Payoneer',
  topUpYourBalanceWithWise: 'Top-up your balance with Wise',
  topUpYourBalanceWithBankTransfer: 'Top-up your balance Bank transfer',
  paymentsOnlyInManualMode: 'payments only in manual mode.',
  toTopUpYourBalanceWithPayoneerPlease: 'To top-up your balance with Payoneer please',
  toTopUpYourBalanceWithBankTransferPlease: 'To top-up your balance with bank transfer please',
  toTopUpYourBalanceWithWisePlease: 'To top-up your balance with Wise please',
  contact: 'contact',
  supportForTransactionDetails: 'support for transaction details.',
  minimumPaymentAmountForThisPaymentMethodIs: 'Please note, minimum payment amount for this payment method is',
  checkPaymentMessage: 'Payment #{paymentId} by <b>{paysystem}</b> was successfully received, an amount of <b>{amount}</b> USD was added to your balance.',
  weAcceptSEPAOrSWIFTBankTransfers: 'We accept SEPA or SWIFT bank transfers only in manual mode.',
  weCurrentlyHaveAPromotion: 'We currently have a special offer: you will receive a <strong>+{ratio}% bonus</strong> on your every payment!',
  forExampleAction: 'For example, if you pay {paySum}$ - you will receive {paySum}+{bonusSum} ({ratio}% bonus) = {amount}$'
};

export default topUpBalance;
