import { Actions, Mutations } from '@/store/enums/StoreEnums';
import StoreGettersService from '@/store/services/StoreGettersService';
import { Getters } from '@/store/enums/GettersEnums';
import { useStore } from 'vuex';
import { AppsDispatcherService } from '@/store/services/AppsDispatcherService';
import { PriceplanDispatcherService } from '@/store/services/PriceplanDispatcherService';
import NoticeDispatcherService from '@/store/services/NoticeDispatcherService';
import { PaymentDispatcherService } from '@/store/services/PaymentDispatcherService';

export class ConstantDispatcherService extends StoreGettersService {

  constructor (store = useStore()) {super(store);}

  public async getAllConstants() {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_CONSTANTS, Actions.GET_CONSTANTS);
  }
  public async getAllLanguages() {
    return await this.getAllRecordsByGetter(Getters.ALL_LANGUAGES, Actions.GET_LANGUAGES);
  }
  public async getCountries () {
    return await this.getAllRecordsByGetter(Getters.ALL_COUNTRIES, Actions.GET_COUNTRIES);
  }
  public async getNoticeTemplates (reloadObject = false) {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_NOTICE_TEMPLATES, Actions.GET_NOTICE_TEMPLATES, {}, reloadObject);
  }
  public async getAllFAQs () {
    return await this.getAllRecordsByGetter(Getters.GET_ALL_FAQS, Actions.GET_FAQS, {}, true);
  }

  public getValidatedCountriesStatus () {
    return this.getValidatedGetter(Getters.GET_COUNTRIES_STATUS);
  }

  public getValidatedLanguagesStatus () {
    return this.getValidatedGetter(Getters.GET_LANGUAGES_STATUS);
  }

  public getValidatedAllConstants () {
    return this.getValidatedGetter(Getters.GET_ALL_CONSTANTS);
  }
  public getValidatedAllCountries () {
    return this.getValidatedGetter(Getters.ALL_COUNTRIES);
  }

  public async getDataLoading() {
    const AppsDispatcher = new AppsDispatcherService(this.getStore());
    const PriceplanDispatcher = new PriceplanDispatcherService(this.getStore());
    const NoticeDispatcher = new NoticeDispatcherService(this.getStore());
    const PaymentDispatcher = new PaymentDispatcherService(this.getStore());

    const constants = await this.getAllConstants();
    if (constants?.length > 0) {
      this.getStore().commit(Mutations.SET_CONSTANTS_STATUS, false);
    }

    const allRequests: Promise<any>[] = [];
    if (AppsDispatcher.getValidatedAppsStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_APPS));
      this.getStore().commit(Mutations.SET_APPS_STATUS, false);
    }
    if (this.getValidatedCountriesStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_COUNTRIES));
      this.getStore().commit(Mutations.SET_COUNTRIES_STATUS, false);
    }
    if (this.getValidatedLanguagesStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_LANGUAGES));
      this.getStore().commit(Mutations.SET_LANGUAGES_STATUS, false);
    }
    if (PriceplanDispatcher.getValidatedPriceplansStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_PRICEPLANS));
      this.getStore().commit(Mutations.SET_PRICEPLANS_STATUS, false);
    }
    if (PriceplanDispatcher.getValidatedCurrentPriceplanStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_CURRENT_PRICEPLAN));
      this.getStore().commit(Mutations.SET_CURRENT_PRICEPLAN_STATUS, false);
    }
    if (NoticeDispatcher.getValidatedNoticesStatus().length) {
      allRequests.push(this.getStore().dispatch(Actions.GET_NOTICES));
      this.getStore().commit(Mutations.SET_NOTICES_STATUS, false);
    }
    if (PaymentDispatcher.getValidatedLegacyBalanceStatus()) {
      allRequests.push(this.getStore().dispatch(Actions.GET_LEGACY_BALANCE));
      this.getStore().commit(Mutations.SET_LEGACY_BALANCE_STATUS, false);
    }
    return allRequests;
  }
}
